import { useState } from "react";
import MetamaskLogin from "./MetamaskLogin";

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <div className="menu-index d-flex align-items-center" data-v-3dfa052f="">
  <div
    className="d-flex justify-content-between"
    data-v-3dfa052f=""
    style={{ height: "100%", width: "100%" }}
  >
    {/* show only when the size over md */}
    <div className="menu-index-desktop d-flex justify-content-between">
    <a
      href="/"
      className="logo-wrapper d-flex align-items-center"
      data-v-3dfa052f=""
    >
      <span className="logo-space" data-v-3dfa052f="">
        <img
          src="/static/images/logo.png"
          className="logo button-effect"
          alt="logo"
        />
      </span>
    </a>
    <div
      className="d-flex pc-nav-index align-items-center"
      data-v-3dfa052f=""
      style={{ flex: "1 1 0%" }}
    >
      <div className="d-flex align-items-center" data-v-3dfa052f="">
        <a href="/" className="pc-nav-link-item" data-v-3dfa052f="">
          <div className="active button-border" data-v-3dfa052f="">
            Home
          </div>
        </a>
        <a href="/game" className="pc-nav-link-item" data-v-3dfa052f="">
          <div className="button-border" data-v-3dfa052f="">
            Game
          </div>
        </a>
        <a
          href="https://docsend.com/view/h9x78qwy4yz2bfd7"
          data-v-3dfa052f=""
        >
          <div className="button-border whitepaper" data-v-3dfa052f="">
            White Paper
          </div>
        </a>
      </div>
      {/* <div className="play-free-btn" data-v-3dfa052f=""> */}
        <MetamaskLogin />
      {/* </div> */}
      </div>
      </div>

{/* show only when size md */}
<div style = {{display: "none"}} className="menu-index-mobile" data-v-3dfa052f="">
  <div
    className="d-flex justify-content-between"
    data-v-3dfa052f=""
    style={{ height: "100%", width: "100%" }}
  >
    <div
      className="d-flex justify-content-center align-items-center"
      data-v-3dfa052f=""
    >
      <img
        src="/static/images/nav-mobile-open.png"
        className="nav-mobile-open-img"
        alt="nav-mobile-open"
        style={{cursor: "pointer"}}
        onClick={() => setShowMenu(!showMenu)}
      />

{/* <div className="play-free-btn" data-v-3dfa052f=""
    style={{height: "70%", fontSize: "1em", width: "15em"}}
    > */}
        <MetamaskLogin />
    {/* </div> */}
    </div>



    </div>

    <div className="full-menu show" data-v-3dfa052f="" hidden={!showMenu}>
  <div className="menu-list" data-v-3dfa052f="">
    <div
      className="d-flex align-items-center justify-content-between mobile-menu-top"
      data-v-3dfa052f=""
    >
      <a href="/" data-v-3dfa052f="">
        <img
          className="nav-menu-mobile-logo"
          src="/static/images/nav-mobile-logo.png"
          alt="nav-mobile-logo"
        />
      </a>
      <img
        className="logo-nav-menu-close"
        src="/static/images/nav-close-mobile.png"
         alt="nav-close-mobile"
         style={{cursor: "pointer"}}
         onClick={() => setShowMenu(!showMenu)}
      />
    </div>
    <ul data-v-3dfa052f="">
      <li className="menu-content" data-v-3dfa052f="">
        <a className="active" href="/" data-v-3dfa052f="">
          HOME
        </a>
      </li>
      <li className="menu-content" data-v-3dfa052f="">
        <a className="" href="/game" data-v-3dfa052f="">
          GAME
        </a>
      </li>
      <li className="menu-content" data-v-3dfa052f="">
        <a
          href="https://docsend.com/view/h9x78qwy4yz2bfd7"
        >
          White Paper
        </a>
      </li>
    </ul>
  </div>
  <div data-v-3dfa052f="" style={{ flex: "1 1 0%", height: "100%"}}/>
</div>
</div>

    {/**/}
  </div>
</div>
  ) 
}

export default Header;